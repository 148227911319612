@use 'src/src/app/scss/abstracts' as *;

.layout {
  $block-name: &;

  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100%;

  &__video-bg {
    @include position(absolute, 0);

    z-index: -1;
    overflow: hidden;

    .video,
    .image {
      @include position(absolute, 0);

      &__inner {
        @include position(absolute, 0);

        aspect-ratio: auto;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  &__content,
  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__main {
    // @include mq($to: lg) {
    // }
  }

  &__footer-wrapper {
    flex-shrink: 0;
  }

  &__mobile-navigation {
    display: none;

    @include mq($until: lg) {
      display: block;
    }
  }

  &__toaster {
    font-size: $font-size-xl;

    @include mq($until: md) {
      font-size: 16px;
    }
  }
}
