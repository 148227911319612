@use '../abstracts' as *;

@keyframes wrong-log {
  0% {
    left: 0;
  }

  20% {
    left: 20px;
  }

  40% {
    left: -20px;
  }

  60% {
    left: 20px;
  }

  80% {
    left: -20px;
  }

  100% {
    left: 0;
  }
}

@keyframes fade-in-up {
  0% {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes shine {
  to {
    background-position-x: -400%;
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
    border-color: $color-base-purple-500;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
    border-color: $color-base-purple-500;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
    border-color: $color-base-purple-500;
  }

  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
    border-color: $color-base-purple-500;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animation-fade-in-up {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  animation-name: fade-in-up;
}
