@use '../abstracts' as *;

@at-root {
  @viewport {
    width: device-width;
  }
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: fade-out($color-semantic-secondary, 0.7);
  -ms-overflow-style: scrollbar;
  text-size-adjust: 100%;
  min-width: 375px;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  background-color: $color-other-body;
  word-break: break-word;
  font-family: $font-family-main;
  font-size: $font-size-xl;
  line-height: $font-line-m;
  color: $color-other-text;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > iframe {
    display: none !important;
  }
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

input[type='search'] {
  appearance: textfield;
}

// area,
// summary,
a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none;
  }

  &:not(:root) {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a {
  // color: $link-color;
  background-color: transparent;

  // transition: color $transition-time;

  //&:hover,
  //&:focus {
  //  color: $link-color--hover;
  //}

  // &:visited {
  //   color: $link-color--hover;
  // }

  &[href^='tel:'] {
    @include mq(xl) {
      // white-space: nowrap;
      // pointer-events: none;
      // text-decoration: inherit;
      // color: inherit;
    }
  }

  &[href^=''] {
    // pointer-events: none !important;
    // color: $text-color !important;
  }
}

::selection {
  background: fade-out($color-semantic-secondary, 0.1);
  color: $color-semantic-main;
}

::-moz-selection {
  background: fade-out($color-semantic-secondary, 0.1);
  color: $color-semantic-main;
}

button {
  appearance: none;
}

a,
button {
  outline: none;

  &:focus {
    outline: none;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  border: 0;
  overflow: visible;
  border-top: 1px solid $color-base-black-100;
}

mark {
  background-color: $color-semantic-main;
}

pre {
  display: block;
  width: 100%;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba($color-base-black-100, 0.08);
  font-size: 0.7em;

  code {
    background: transparent;
  }
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

#storybook-docs {
  .sbdocs-content {
    max-width: 1500px !important;

    .h-pt-0 {
      padding-top: 0;
    }

    .h-pb-0 {
      padding-bottom: 0;
    }
  }

  .docblock-typeset {
    color: $color-semantic-main;

    > div {
      > div {
        &:first-child {
          flex: 0 0 auto;
        }
      }
    }
  }

  .docblock-icongallery {
    color: $color-semantic-main;
  }
}

.attention {
  color: red !important;
}
